import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Layout/Layout';
import ProjectDetail from './ProjectDetail';
import '../../contentful/fragments';

export default props => {
  const data = {
    ...props.data,
    allProjects: props.data.allProjects.nodes,
  };
  return (
    <Layout>
      <ProjectDetail {...props} data={data} />
    </Layout>
  );
};

export const query = graphql`
  query($locale: String!, $slug: String!) {
    project: contentfulProjectDetailPage(
      node_locale: { eq: $locale }
      sluggedName: { eq: $slug }
    ) {
      ...projectDetailPageFragment
    }

    allProjects: allContentfulProjectDetailPage(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [createdAt], order: DESC }
    ) {
      nodes {
        sluggedName
        category
        previewImages {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
