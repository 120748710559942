import React, { Fragment, useEffect } from 'react';
import './ProjectDetail.scss';
import {
  DetailGrid,
  ListWithHeadline,
  FullViewportStage,
  Footer,
  ProjectMosaic,
  DetailPageFooterNav,
} from '../../components/index';
import { Link } from 'gatsby';
import { parse } from 'query-string';
import VideoStage from '../../components/VideoStage/VideoStage';
import { MoonLoader } from 'react-spinners';
import Carousel from '../../components/Carousel/Carousel';
import CarouselScreen from '../../components/Carousel/CarouselScreen';
import ScrollTransition from '../../components/Carousel/Transitions/ScrollTransition';
import SlideTransition from '../../components/Carousel/Transitions/SlideTransition';
import { HorizontalSlider } from '../../components';
import ScrollDown from '../../components/ScrollDown/ScrollDown';

const AboveTheFold = ({
  goToScreen,
  projectName,
  play,
  playHandler,
  client,
}) => (
  <FullViewportStage centered>
    <FullViewportStage centered>
      <h4 className={'typography__header4 projectDetail__clientName'}>
        {client}
      </h4>
      <h1 className={'typography__header2'}>{projectName}</h1>

      <p
        className={'typography__link typography__link--readMore scrollable'}
        onClick={playHandler}
      >
        {play}
      </p>
      <ScrollDown onClick={() => goToScreen(1)} />
    </FullViewportStage>
  </FullViewportStage>
);

const LastScreen = ({
  scrollToTop,
  goToScreen,
  currentProject,
  allProjects,
  footerNavLabels,
  location,
}) => {
  const goToTop = async () => {
    goToScreen(0, true);
  };

  useEffect(() => {
    const hash = location && location.hash;
    if (hash.length) {
      goToScreen(hash, true);
    }
  }, []);

  return (
    <Fragment>
      <FullViewportStage black regularOverflow centered>
        <DetailPageFooterNav
          labels={footerNavLabels}
          currentItem={currentProject}
          allItems={allProjects}
          categoryQuery={parse(location.search).category}
        />
        <Footer onArrowClick={() => goToTop()} />{' '}
      </FullViewportStage>
    </Fragment>
  );
};

class ProjectDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSpinner: !props.data.project,
      videoIsOpen: false,
      makingOfVideoIsOpen: false,
    };
  }

  hasEpisodesSection = () => {
    if (this.props.data.project) {
      const {
        episodes,
        episodesPreviewImages,
        episodeTitles,
      } = this.props.data.project;
      return !!(
        episodes &&
        episodes.length > 0 &&
        episodesPreviewImages &&
        episodesPreviewImages.length > 0 &&
        episodeTitles &&
        episodeTitles.length > 0
      );
    }
  };

  render() {
    const { showSpinner } = this.state;
    const {
      pageContext,
      location,
      data: { project, allProjects },
    } = this.props;
    const { text } = pageContext;
    const {
      projectName,
      vimeoID,
      shortLoopVideoPreviewImage,
      shortPreviewVideoID,
      client,
      director,
      agency,
      dop,
      category,
      editor,
      projectInfoText: { projectInfoText },
      projectStillImages,
      projectMosaicTitle,
      projectMosaicGallery,
      claim,
      makingOfVideoHeadline,
      makingOfVimeoID,
      makingOfMosaicGallery,
      episodes,
      episodesPreviewImages,
      episodeTitles,
    } = project || {};
    const {
      back,
      play,
      client: clientLabel,
      agency: agencyLabel,
      category: categoryLabel,
      director: directorLabel,
      dop: dopLabel,
      editor: editorLabel,
      projectInfo,
    } = text.projectDetail;

    const footerNavLabels = text.detailPagesFooterNav;

    const stillImagesSliderData =
      projectStillImages &&
      projectStillImages.map(step => ({
        image: step,
      }));

    const makingOfSliderData =
      this.hasEpisodesSection() &&
      episodesPreviewImages.map((step, idx) => ({
        image: step,
        title: episodeTitles[idx],
        link: {
          onClick: () => this.setState({ [`video${idx}isOpen`]: true }),
          label: play,
        },
      }));

    return (
      <div className={'projectDetail'}>
        {project ? (
          <Fragment>
            <Link
              className={'scrollable'}
              to={
                this.props.location.pathname
                  .split('/')
                  .filter(Boolean) // Remove trailing slash if found
                  .slice(0, -1)
                  .join('/') + `/${this.props.location.search}`
              }
            >
              <div
                className={'projectDetail__iconLink'}
                onClick={() => {
                  this.setState({ videoIsOpen: true });
                }}
              >
                <img alt={'back button'} src={'assets/icons/back.svg'} />
                <p className={'typography__paragraph'}>{back}</p>
              </div>
            </Link>
            <VideoStage
              videoId={shortPreviewVideoID}
              videoBackground
              imageBackgroundSrc={shortLoopVideoPreviewImage}
            />

            <Carousel location={location}>
              <CarouselScreen transition={ScrollTransition}>
                <AboveTheFold
                  play={play}
                  projectName={projectName}
                  playHandler={() => this.setState({ videoIsOpen: true })}
                  client={client}
                />
              </CarouselScreen>
              <CarouselScreen transition={ScrollTransition} scrollable={true}>
                <FullViewportStage centered black>
                  <DetailGrid.Container
                    label={projectInfo}
                    top={
                      <DetailGrid.Row
                        className={'container__row--breakOnMobile'}
                      >
                        <DetailGrid.Cell>
                          <ListWithHeadline
                            headline={clientLabel}
                            list={client}
                          />
                          <ListWithHeadline
                            headline={directorLabel}
                            list={director}
                          />
                        </DetailGrid.Cell>
                        <DetailGrid.Cell>
                          <ListWithHeadline
                            headline={agencyLabel}
                            list={agency}
                          />
                          <ListWithHeadline headline={dopLabel} list={dop} />
                        </DetailGrid.Cell>
                        <DetailGrid.Cell>
                          <ListWithHeadline
                            headline={categoryLabel}
                            list={category}
                          />
                          <ListWithHeadline
                            headline={editorLabel}
                            list={editor}
                          />
                        </DetailGrid.Cell>
                      </DetailGrid.Row>
                    }
                    bottom={
                      <DetailGrid.Cell>
                        <p
                          className={
                            'typography__paragraph typography__paragraph--limited'
                          }
                        >
                          {projectInfoText}
                        </p>
                      </DetailGrid.Cell>
                    }
                  />
                </FullViewportStage>
              </CarouselScreen>
              {projectStillImages && projectStillImages.length > 0 && (
                <CarouselScreen transition={SlideTransition}>
                  <FullViewportStage centered black scrollable>
                    {/* <div style={{ marginTop: '340px' }}> */}
                    <HorizontalSlider data={stillImagesSliderData} />
                    {/* </div> */}
                  </FullViewportStage>
                </CarouselScreen>
              )}
              {projectMosaicGallery && (
                <CarouselScreen transition={SlideTransition} scrollable>
                  <FullViewportStage
                    regularOverflow
                    black
                    centered
                    // style={{ paddingBottom: '120px', paddingTop: '120px' }}
                  >
                    <ProjectMosaic
                      title={projectMosaicTitle}
                      images={projectMosaicGallery}
                    />
                  </FullViewportStage>
                </CarouselScreen>
              )}
              {claim && claim.claim && claim.claim.length > 1 && (
                <CarouselScreen transition={SlideTransition} scrollable={true}>
                  <FullViewportStage centered black scrollable={true}>
                    <div className={'projectDetail__claim'}>
                      <p className={'typography__testimonial'}>{claim.claim}</p>
                    </div>
                  </FullViewportStage>
                </CarouselScreen>
              )}
              {/* {(!!makingOfVimeoID ||
                this.hasEpisodesSection() ||
                makingOfMosaicGallery) && ( */}
              {makingOfVimeoID && (
                <CarouselScreen transition={SlideTransition} scrollable={true}>
                  <FullViewportStage
                    black
                    notFullHeight={!project.makingOfVimeoID}
                    centered={project.makingOfVimeoID}
                    regularOverflow={!project.makingOfVimeoID}
                    spacedTopBottom={!project.makingOfVimeoID}
                  >
                    <VideoStage
                      videoId={shortPreviewVideoID}
                      videoBackground
                      imageBackgroundSrc={shortLoopVideoPreviewImage}
                    >
                      <h2 className={'typography__header2'}>
                        {makingOfVideoHeadline || 'Behind the Scenes'}
                      </h2>
                      <p
                        className={
                          'typography__link typography__link--readMore'
                        }
                        onClick={() =>
                          this.setState({ makingOfVideoIsOpen: true })
                        }
                      >
                        {play}
                      </p>
                    </VideoStage>
                    )}
                  </FullViewportStage>
                </CarouselScreen>
              )}
              
              {makingOfMosaicGallery && (
                <CarouselScreen transition={SlideTransition} scrollable={true}>
                  <FullViewportStage black regularOverflow scrollable={true}>
                    <ProjectMosaic
                      id="making-of-mosaic"
                      images={makingOfMosaicGallery}
                    />
                  </FullViewportStage>
                </CarouselScreen>
              )}
              {this.hasEpisodesSection() && (
                <CarouselScreen transition={SlideTransition} scrollable={true}>
                  <FullViewportStage
                    black
                    centered
                    regularOverflow
                    spacedTopBottom
                  >
                    <HorizontalSlider data={makingOfSliderData} />
                  </FullViewportStage>
                </CarouselScreen>
              )}
              <CarouselScreen transition={SlideTransition} scrollable={true}>
                <LastScreen
                  footerNavLabels={footerNavLabels}
                  currentProject={project}
                  allProjects={allProjects}
                  location={this.props.location}
                />
              </CarouselScreen>
            </Carousel>

            <VideoStage
              openVideo={this.state.videoIsOpen}
              onClose={() => this.setState({ videoIsOpen: false })}
              videoId={vimeoID}
              quality={'720p'}
            />
            {makingOfVimeoID && (
              <VideoStage
                openVideo={this.state.makingOfVideoIsOpen}
                onClose={() => this.setState({ makingOfVideoIsOpen: false })}
                videoId={makingOfVimeoID}
                quality={'720p'}
              />
            )}
            {this.hasEpisodesSection() &&
              episodes.map((episode, idx) => (
                <VideoStage
                  key={episode}
                  openVideo={this.state[`video${idx}isOpen`]}
                  onClose={() =>
                    this.setState({ [`video${idx}isOpen`]: false })
                  }
                  videoId={episode}
                  Footer
                />
              ))}
          </Fragment>
        ) : (
          <FullViewportStage centered>
            <MoonLoader size={60} color={'white'} loading={showSpinner} />
          </FullViewportStage>
        )}
      </div>
    );
  }
}

export default ProjectDetail;
